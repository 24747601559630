import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

const prefix: any = 'custom'

const chevronLeft: IconDefinition = {
  icon: [256, 512, [], 'f053', 'M231.293 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L70.393 256 251.092 74.87c4.686-4.686 4.686-12.284 0-16.971L231.293 38.1c-4.686-4.686-12.284-4.686-16.971 0L4.908 247.515c-4.686 4.686-4.686 12.284 0 16.971L214.322 473.9c4.687 4.686 12.285 4.686 16.971-.001z'],
  iconName: 'chevron-left' as any,
  prefix
}

const lightTimes: IconDefinition = {
  icon: [320, 512, [], 'f00d', 'M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z'],
  iconName: 'light-times' as any,
  prefix
}

const arrowRightToLine: IconDefinition = {
  icon: [448, 512, [], 'f0a3', 'M448 96c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 320c0 17.7 14.3 32 32 32s32-14.3 32-32l0-320zM310.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L210.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l178.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z'],
  iconName: 'arrow-right-to-line' as any,
  prefix
}

const solidPlus: IconDefinition = {
  icon: [512, 512, [], 'f067', 'M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 192H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H224V480c0 17.7 14.3 32 32 32s32-14.3 32-32V288l192 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-192 0 0-192z'],
  iconName: 'solid-plus' as any,
  prefix
}

const solidSpinnerThird: IconDefinition = {
  icon: [512, 512, [], 'f3f4', 'M456.433 371.72l-27.79-16.045c-7.192-4.152-10.052-13.136-6.487-20.636 25.82-54.328 23.566-118.602-6.768-171.03-30.265-52.529-84.802-86.621-144.76-91.424C262.35 71.922 256 64.953 256 56.649V24.56c0-9.31 7.916-16.609 17.204-15.96 81.795 5.717 156.412 51.902 197.611 123.408 41.301 71.385 43.99 159.096 8.042 232.792-4.082 8.369-14.361 11.575-22.424 6.92z'],
  iconName: 'solid-spinner-third' as any,
  prefix
}

export default [
  arrowRightToLine,
  chevronLeft,
  lightTimes,
  solidPlus,
  solidSpinnerThird
]
