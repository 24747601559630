import { FlowStep } from '@icepanel/platform-api-client'

/**
 * Sort a and b by index and fallback to handleId, name properties
 * @param a
 * @param b
 * @returns
 */
export const index = <T extends { index?: number, handleId?: string, name?: string, id?: string }>(a: T, b: T) => {
  if (a.index !== undefined && b.index !== undefined && a.index !== b.index) {
    return a.index > b.index ? 1 : -1
  } else if (a.index !== undefined && b.index === undefined) {
    return -1
  } else if (a.index === undefined && b.index !== undefined) {
    return 1
  } else if (a.name !== undefined && b.name !== undefined && a.name !== b.name) {
    return a.name.localeCompare(b.name)
  } else if (a.handleId !== undefined && b.handleId !== undefined && a.handleId !== b.handleId) {
    return a.handleId < b.handleId ? 1 : -1
  } else if (a.id !== undefined && b.id !== undefined && a.id !== b.id) {
    return a.id < b.id ? 1 : -1
  } else {
    return 0
  }
}

/**
 * Sort a and b by path index
 * @param a
 * @param b
 * @returns
 */
export const pathIndex = <T extends { pathIndex: number | null }>(a: T, b: T) => {
  if (a.pathIndex !== null && b.pathIndex !== null && a.pathIndex !== b.pathIndex) {
    return a.pathIndex > b.pathIndex ? 1 : -1
  } else if (a.pathIndex !== null && b.pathIndex === null) {
    return 1
  } else if (a.pathIndex === null && b.pathIndex !== null) {
    return -1
  } else {
    return 0
  }
}

/**
 * Group path flow steps into the same index, sort the resulting array and correct incorrect indexes
 * @param flowSteps
 */
export const flowSteps = (flowSteps: Record<string, FlowStep>) => ({
  // sort the flow steps without the path children
  ...Object
    .values(flowSteps)
    .filter(o => !o.pathId)
    .reduce<FlowStep[][]>((p, c) => {
      // group steps with the same index
      const existingGroup = p.find(o => c.type?.endsWith('-path') && o[0].type?.endsWith('-path') && c.index === o[0].index)
      if (existingGroup) {
        existingGroup.push(c)
        return p
      } else {
        return [...p, [c]]
      }
    }, [])
    .sort((a, b) => index(a[0], b[0]))
    .reduce<Record<string, FlowStep>>((p, c, index) => ({
      ...p,

      // sort the paths according to the path index
      ...c
        .sort(pathIndex)
        .reduce<Record<string, FlowStep>>((pp, cc, ccIndex) => ({
          ...pp,
          [cc.id]: {
            ...cc,
            index,
            pathIndex: cc.type?.endsWith('-path') ? ccIndex : null
          },

          // insert the path children back in and sort them according to the path index
          ...Object
            .values(flowSteps)
            .filter(o => o.pathId === cc.id)
            .sort(pathIndex)
            .reduce<Record<string, FlowStep>>((ppp, ccc, cccIndex) => ({
              ...ppp,
              [ccc.id]: {
                ...ccc,
                index,
                pathIndex: cccIndex
              }
            }), {})
        }), {})
    }), {})
})
