import { format } from 'date-fns'
import { enGB, enUS } from 'date-fns/locale'

const locales = {
  enGB,
  enUS
} as any

export const capitalize = (value: string | number | undefined) => {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export const date = (value: string | number | Date | undefined, dateFormat = 'P') => {
  if (!value) {
    return ''
  }
  if (typeof value === 'string') {
    value = new Date(value)
  }
  return format(value, dateFormat, {
    locale: locales[window.navigator.language.replace('-', '')]
  })
}

export const time = (value: string | number | Date | undefined) => {
  if (!value) {
    return ''
  }
  const date = new Date(value)
  return format(date, 'HH:mm')
}

export const dateTime = (value: string | number | Date | undefined) => {
  if (!value) {
    return ''
  }
  return `${date(value)} at ${time(value)}`
}
