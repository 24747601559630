import { TAG_COLOR_ORDER } from '@icepanel/app-canvas'
import { TagColor } from '@icepanel/platform-api-client'

export default (id: string) => {
  const tagColors = TAG_COLOR_ORDER.filter(o => o !== 'black' && o !== 'white' && o !== 'grey')
  const alphabet = Array.from(Array(26)).map((e, i) => i + 65).map((x) => String.fromCharCode(x))
  const indexes = [
    ...Array.from(Array(10).keys()),
    ...alphabet.map(o => o.toUpperCase()),
    ...alphabet.map(o => o.toLowerCase())
  ]
  const indexMap = indexes.reduce<Record<string, TagColor>>((p, c, i) => ({
    ...p,
    [c]: tagColors[Math.floor(i * (tagColors.length / indexes.length))]
  }), {})
  return indexMap[id[0]]
}
