const DEFAULT_FIREBASE_APP_CONFIG = 'eyJhcGlLZXkiOiJBSXphU3lCWWd2M2tsdWNwN2thVVRRZUI3V0ZQQWtEX1BIUHJFaTgiLCJhcHBJZCI6IjE6ODAxNDM4NTYyODE3OndlYjo2M2NhYTgwM2I1NjBjY2E0ODc1ZTAwIiwiYXV0aERvbWFpbiI6ImFwcC5pY2VwYW5lbC5kZXYiLCJkYXRhYmFzZVVSTCI6IiIsIm1lYXN1cmVtZW50SWQiOiIiLCJtZXNzYWdpbmdTZW5kZXJJZCI6IjgwMTQzODU2MjgxNyIsInByb2plY3RJZCI6ImljZXBhbmVsLXN0YWciLCJzdG9yYWdlQnVja2V0IjoiaWNlcGFuZWwtc3RhZy5hcHBzcG90LmNvbSJ9'
const DEFAULT_FIREBASE_LEGACY_APP_CONFIG = 'eyJhcGlLZXkiOiJBSXphU3lCWWd2M2tsdWNwN2thVVRRZUI3V0ZQQWtEX1BIUHJFaTgiLCJhcHBJZCI6IjE6ODAxNDM4NTYyODE3OndlYjo2M2NhYTgwM2I1NjBjY2E0ODc1ZTAwIiwiYXV0aERvbWFpbiI6ImFwcC5pY2VwYW5lbC5kZXYiLCJkYXRhYmFzZVVSTCI6IiIsIm1lYXN1cmVtZW50SWQiOiIiLCJtZXNzYWdpbmdTZW5kZXJJZCI6IjgwMTQzODU2MjgxNyIsInByb2plY3RJZCI6ImljZXBhbmVsLXN0YWciLCJzdG9yYWdlQnVja2V0IjoiaWNlcGFuZWwtc3RhZy5hcHBzcG90LmNvbSJ9'

export const VERSION = '80.0'

export const ICE_ENV = process.env.VUE_APP_ICE_ENV || ''

export const IS_PRODUCTION = ICE_ENV.startsWith('production')
export const IS_STAGING = ICE_ENV.startsWith('staging')
export const IS_DEV = ICE_ENV.startsWith('dev')

export const FIREBASE_APP_CONFIG = JSON.parse(Buffer.from(process.env.VUE_APP_FIREBASE_APP_CONFIG || DEFAULT_FIREBASE_APP_CONFIG, 'base64').toString())
export const FIREBASE_LEGACY_APP_CONFIG = JSON.parse(Buffer.from(process.env.VUE_APP_FIREBASE_LEGACY_APP_CONFIG || DEFAULT_FIREBASE_LEGACY_APP_CONFIG, 'base64').toString())

export const APP_URL = process.env.VUE_APP_APP_URL || 'http://localhost:8080'

export const SHARE_URL = process.env.VUE_APP_SHARE_URL || 'http://localhost:8080/s'

export const API_URL = process.env.VUE_APP_API_URL || (window.Cypress && window.Cypress.env('API_URL')) || 'http://localhost:4000/v1'

export const REAL_TIME_URL = process.env.VUE_APP_REAL_TIME_URL || (window.Cypress && window.Cypress.env('REAL_TIME_URL')) || 'http://localhost:4100'

export const ICON_URL = process.env.VUE_APP_ICON_URL || (window.Cypress && window.Cypress.env('ICON_URL')) || 'https://icon.icepanel.dev'

export const MIXPANEL_TOKEN = process.env.VUE_APP_MIXPANEL_TOKEN || 'c8ac28a418eb7559e4c2ed4408b52db4'

export const AZURE_DEVOPS_APP_ID = process.env.VUE_APP_AZURE_DEVOPS_APP_ID || '1B0D5D6E-A406-4EFB-B08D-98D44A53A6D6'
export const AZURE_DEVOPS_REDIRECT_URI = process.env.VUE_APP_AZURE_DEVOPS_REDIRECT_URI || 'https://app.icepanel.dev/azure-devops/authorization'

export const BITBUCKET_APP_ID = process.env.VUE_APP_BITBUCKET_APP_ID || 'yzPuk8A2pkqHzCSHUZ'

export const GITHUB_APP_ID = IS_PRODUCTION ? 'icepanel' : 'icepanel-staging'

export const GITLAB_APP_ID = process.env.VUE_APP_GITLAB_APP_ID || 'f665e2f585e439cecd21b1ca0f57d998d52398045c68d69bd74286e02ec4ee33'
export const GITLAB_REDIRECT_URI = process.env.VUE_APP_GITLAB_REDIRECT_URI || 'https://app.icepanel.dev/gitlab/authorization'

export const IS_SHARE_LINK = window.location.host.startsWith('s.') || window.location.host.startsWith('www.s.') || (IS_DEV && !!window.location.pathname.match(/^\/[a-zA-Z0-9]{14}\/[a-zA-Z0-9]{4}(\/|$)/))
export const IS_SAFARI = (navigator.vendor.match(/apple/i) || '').length > 0
