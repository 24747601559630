import { markdownTable } from 'markdown-table'
import Delta from 'quill-delta'
import Op from 'quill-delta/dist/Op'
import remarkGfm from 'remark-gfm'
import markdown from 'remark-parse'
import unified from 'unified'

import { Node } from '@/types/markdown'

export const deltaToMarkdown = (delta: Delta) => {
  let output = ''
  let curLine = ''
  let curListIndex = 0
  let curOrderedListIndex: number[] = []
  let curTableId = ''
  let curTable: string[][] = []
  let curTableAlign: string[] = []
  let curCodeBlock = ''
  let curCodeBlockLang = ''

  const walkFormatOp = (o: Op) => {
    if (typeof o.insert === 'string') {
      let curText = o.insert
      Object.keys(o.attributes || {}).forEach(key => {
        curText = applyFormat(curText, key, o.attributes || {})
      })
      curLine = `${curLine}${curText}`
    } else {
      throw new Error('Walking format operation without string insert')
    }
  }

  const applyFormat = (insert: string, name: string, attrs: any) => {
    const startSpace = insert.startsWith(' ') ? ' ' : ''
    const endSpace = insert.endsWith(' ') ? ' ' : ''
    switch (name) {
      case 'italic':
        return `${startSpace}*${insert.trim()}*${endSpace}`
      case 'bold':
        return `${startSpace}**${insert.trim()}**${endSpace}`
      case 'code':
        return `${startSpace}\`${insert.trim()}\`${endSpace}`
      case 'strike':
        return `${startSpace}~~${insert.trim()}~~${endSpace}`
      case 'link':
        return `[${insert.trim()}](${attrs.link}${attrs.title ? ` "${attrs.title}"` : ''})`
      default:
        return insert
    }
  }

  const walkImageOp = (insert: any, attributes: any) => {
    curLine = `${curLine}![${attributes?.alt || ''}](${insert.image}${attributes?.title ? ` "${attributes.title}"` : ''})`
  }

  const walkMentionOp = (insert: any) => {
    if (curLine.endsWith('!')) {
      curLine += ' '
    }
    curLine = `${curLine}[${insert.mention.value || ''}](${insert.mention.denotationChar || ''}${insert.mention.id || ''})`
  }

  const walkNewlineOp = (o: Op) => {
    if (o.attributes?.['code-block']) {
      curCodeBlockLang = curCodeBlockLang ?? o.attributes?.['code-block'] as string
      curCodeBlock = `${curCodeBlock}${curLine}${o.insert}`
      curLine = ''
    } else if (typeof o.attributes?.table === 'string') {
      if (curTableId !== o.attributes.table) {
        curTableId = o.attributes.table
        curTable.push([curLine])
        curTableAlign = o.attributes.align ? [o.attributes.align as string] : []
      } else {
        curTable[curTable.length - 1].push(curLine)
        if (o.attributes.align) {
          curTableAlign.push(o.attributes.align as string)
        }
      }
      curLine = ''
    } else {
      output = `${output ? `${output}\n` : output}`

      if (!o.attributes?.list && curListIndex !== 0) {
        curListIndex = 0
        output = `${output ? `${output}\n` : output}`
      }

      if (o.attributes?.list !== 'ordered' && curOrderedListIndex.length) {
        curOrderedListIndex = []
      }

      let table = ''
      if (curTableId && curTable) {
        table = `${markdownTable(curTable, { align: curTableAlign })}\n\n`
        curTableId = ''
        curTable = []
        curTableAlign = []
        output = `${output}${table}`
      }

      let codeBlock = ''
      if (curCodeBlock) {
        codeBlock = `\`\`\`${curCodeBlockLang === 'plain' ? '' : curCodeBlockLang}\n${curCodeBlock}\`\`\`\n\n`
        curCodeBlock = ''
        curCodeBlockLang = ''
        output = `${output}${codeBlock}`
      }

      const indentCount = o.attributes?.indent as number ?? 0
      if (typeof o.attributes?.header === 'number') {
        const hashes = '#'.repeat(o.attributes.header)
        output = `${output}${hashes} ${curLine}${o.insert}`
        curLine = ''
      } else if (o.attributes?.divider) {
        output = `${output}---${o.insert}`
        curLine = ''
      } else if (o.attributes?.blockquote) {
        const prefix = '>'.repeat(indentCount + 1)
        output = `${output}${prefix} ${curLine}${o.insert}`
        curLine = ''
      } else if (typeof o.attributes?.list === 'string') {
        curListIndex++
        const indent = '    '.repeat(indentCount)
        let prefix = ''
        if (o.attributes?.list === 'checked') {
          prefix = '- [x]'
        } else if (o.attributes?.list === 'unchecked') {
          prefix = '- [ ]'
        } else if (o.attributes?.list === 'ordered') {
          curOrderedListIndex.splice(indentCount + 1)
          if (!curOrderedListIndex[indentCount]) {
            curOrderedListIndex[indentCount] = 1
          } else {
            curOrderedListIndex[indentCount]++
          }
          prefix = `${curOrderedListIndex[indentCount]}.`
        } else {
          prefix = '-'
        }
        output = `${output}${indent}${prefix} ${curLine}`
        curLine = ''
      } else {
        output = `${output}${curLine}${o.insert}`
        curLine = ''
      }
    }
  }

  delta
    .map(o => {
      if (typeof o.insert === 'string' && o.insert !== '\n') {
        const lines = o.insert.split('\n')
        return lines.map((insert, i) => {
          if (i === lines.length - 1) {
            return [
              {
                ...o,
                insert
              }
            ] as Op[]
          } else {
            return [
              {
                insert
              },
              {
                ...o,
                insert: '\n'
              }
            ] as Op[]
          }
        }).flat()
      } else {
        return [o]
      }
    })
    .flat()
    .forEach(o => {
      if (o.insert === '\n') {
        walkNewlineOp(o)
      } else if (typeof o.insert === 'string') {
        walkFormatOp(o)
      } else if (typeof o.insert === 'object' && 'image' in o.insert) {
        walkImageOp(o.insert, o.attributes)
      } else if (typeof o.insert === 'object' && 'mention' in o.insert) {
        walkMentionOp(o.insert)
      }
    })

  let table = ''
  if (curTableId && curTable) {
    table = `${markdownTable(curTable, { align: curTableAlign })}\n\n`
    curTableId = ''
    curTable = []
    curTableAlign = []
  }

  let codeBlock = ''
  if (curCodeBlock) {
    codeBlock = `\`\`\`${curCodeBlockLang === 'plain' ? '' : curCodeBlockLang}\n${curCodeBlock}\`\`\`\n\n`
    curCodeBlock = ''
    curCodeBlockLang = ''
  }

  return `${output}\n${table}${codeBlock}`.trim()
}

export const markdownToDelta = (text: string): Delta => {
  const processor = unified().use(remarkGfm).use(markdown)
  const tree = processor.parse(text) as Node

  const walkNode = (node: Node, parentNode?: Node, indent = 0): Op[] => {
    switch (node.type) {
      case 'heading': {
        return [
          ...node.children
            .map(o => walkNode(o, node, indent))
            .flat(),
          {
            attributes: {
              header: node.depth
            },
            insert: '\n'
          }
        ]
      }

      case 'link': {
        return node.url.startsWith('@')
          ? [{
              insert: {
                mention: {
                  denotationChar: '@',
                  id: node.url.replace(/^@/, ''),
                  value: node.children.find(o => o.type === 'text')?.value
                }
              }
            }]
          : node.children
            .map(o => walkNode(o, node, indent))
            .flat()
            .map(o => ({
              ...o,
              attributes: {
                ...o.attributes,
                link: node.url,
                title: node.title
              }
            }))
      }

      case 'strong': {
        return node.children
          .map(o => walkNode(o, node, indent))
          .flat()
          .map(o => ({
            ...o,
            attributes: {
              ...o.attributes,
              bold: true
            }
          }))
      }

      case 'delete': {
        return node.children
          .map(o => walkNode(o, node, indent))
          .flat()
          .map(o => ({
            ...o,
            attributes: {
              ...o.attributes,
              strike: true
            }
          }))
      }

      case 'emphasis': {
        return node.children
          .map(o => walkNode(o, node, indent))
          .flat()
          .map(o => ({
            ...o,
            attributes: {
              ...o.attributes,
              italic: true
            }
          }))
      }

      case 'inlineCode': {
        return [
          {
            attributes: {
              code: true
            },
            insert: node.value
          }
        ]
      }

      case 'code': {
        return node.value
          .split('\n')
          .map(o => [
            {
              insert: o
            },
            {
              attributes: {
                'code-block': node.lang || 'plain'
              },
              insert: '\n'
            }
          ]).flat()
      }

      case 'paragraph': {
        return [
          ...node.children
            .map(o => walkNode(o, node, indent))
            .flat(),
          {
            insert: '\n'
          }
        ]
      }

      case 'table': {
        const tableId = Math.random().toString(36).slice(-6)
        const columns = node.align.length
        let tableIndex = 0
        return [
          ...node.children
            .map(o => walkNode(o, node, indent))
            .flat()
            .map(o => {
              if (o.insert === '\n' && o.attributes?.table) {
                const y = Math.floor(tableIndex / columns)
                const x = tableIndex - (columns * y)
                tableIndex++
                return {
                  ...o,
                  attributes: {
                    ...o.attributes,
                    align: node.align[x],
                    table: `${tableId}-${o.attributes.table || ''}`
                  }
                }
              } else {
                return o
              }
            })
        ]
      }

      case 'tableRow': {
        const tableRowId = Math.random().toString(36).slice(-6)
        return node.children
          .map(o => [
            ...walkNode(o, node, indent),
            {
              attributes: {
                table: tableRowId
              },
              insert: '\n'
            }
          ])
          .flat()
      }

      case 'tableCell': {
        return node.children
          .map(o => walkNode(o, node, indent))
          .flat()
          .filter(o => o.insert !== '\n')
      }

      case 'list': {
        return node.children
          .map(o => walkNode(o, node, indent))
          .flat()
      }

      case 'listItem': {
        let list = ''
        if (node.checked === true) {
          list = 'checked'
        } else if (node.checked === false) {
          list = 'unchecked'
        } else if (parentNode?.ordered) {
          list = 'ordered'
        } else {
          list = 'bullet'
        }
        return [
          ...node.children
            .filter(o => o.type !== 'list')
            .map(o => walkNode(o, node, indent))
            .flat()
            .filter(o => o.insert !== '\n'),
          {
            attributes: {
              indent,
              list
            },
            insert: '\n'
          },
          ...node.children
            .filter(o => o.type === 'list')
            .map(o => walkNode(o, node, indent + 1))
            .flat()
        ]
      }

      case 'thematicBreak': {
        return [
          {
            attributes: {
              divider: true
            },
            insert: '\n'
          }
        ]
      }

      case 'blockquote': {
        return [
          ...node.children
            .map(o => walkNode(o, node, indent + 1))
            .flat()
            .filter(o => o.insert !== '\n' || o.attributes?.blockquote),
          ...node.children.some(o => o.type === 'blockquote')
            ? []
            : [
                {
                  attributes: {
                    blockquote: true,
                    indent
                  },
                  insert: '\n'
                }
              ]
        ]
      }

      case 'image': {
        return [
          {
            attributes: {
              alt: node.alt,
              title: node.title
            },
            insert: {
              image: node.url
            }
          }
        ]
      }

      case 'text': {
        const sections = node.value.split('\n')
        return sections.map((o, i) => {
          if (i === sections.length - 1) {
            return [
              {
                insert: o
              }
            ]
          } else {
            return [
              {
                insert: o
              },
              {
                insert: '\n'
              }
            ]
          }
        }).flat()
      }

      default: {
        return []
      }
    }
  }

  const ops = tree.children
    .map(o => walkNode(o))
    .flat()

  return new Delta({
    ops
  })
}

export const markdownStats = (text: string) => {
  const processor = unified().use(remarkGfm).use(markdown)
  const tree = processor.parse(text) as Node

  const countWords = (str: string) => str.trim().split(/\s+/).length

  let textWordCount = 0
  let heading1Count = 0
  let heading2Count = 0
  let heading3Count = 0
  let heading4Count = 0
  let heading5Count = 0
  let heading6Count = 0
  let strongCount = 0
  let linkCount = 0
  let strikethroughCount = 0
  let inlineCodeCount = 0
  let emphasisCount = 0
  let codeCount = 0
  let paragraphCount = 0
  let tableCount = 0
  let tableRowCount = 0
  let tableCellCount = 0
  let listCount = 0
  let listItemCount = 0
  let dividerCount = 0
  let blockquoteCount = 0
  let textCharacterCount = 0
  let imageCount = 0

  const walkNode = (node: Node) => {
    switch (node.type) {
      case 'heading': {
        switch (node.depth) {
          case 1: {
            heading1Count++
            break
          }
          case 2: {
            heading2Count++
            break
          }
          case 3: {
            heading3Count++
            break
          }
          case 4: {
            heading4Count++
            break
          }
          case 5: {
            heading5Count++
            break
          }
          case 6: {
            heading6Count++
            break
          }
        }
        node.children.forEach(o => walkNode(o))
        break
      }

      case 'link': {
        linkCount++
        node.children.forEach(o => walkNode(o))
        break
      }

      case 'strong': {
        strongCount++
        node.children.forEach(o => walkNode(o))
        break
      }

      case 'delete': {
        strikethroughCount++
        node.children.map(o => walkNode(o))
        break
      }

      case 'emphasis': {
        emphasisCount++
        node.children.forEach(o => walkNode(o))
        break
      }

      case 'inlineCode': {
        inlineCodeCount++
        break
      }

      case 'code': {
        codeCount++
        break
      }

      case 'paragraph': {
        paragraphCount++
        node.children.forEach(o => walkNode(o))
        break
      }

      case 'table': {
        tableCount++
        node.children.forEach(o => walkNode(o))
        break
      }

      case 'tableRow': {
        tableRowCount++
        node.children.forEach(o => walkNode(o))
        break
      }

      case 'tableCell': {
        tableCellCount++
        node.children.forEach(o => walkNode(o))
        break
      }

      case 'list': {
        listCount++
        node.children.forEach(o => walkNode(o))
        break
      }

      case 'listItem': {
        listItemCount++
        node.children.forEach(o => walkNode(o))
        break
      }

      case 'thematicBreak': {
        dividerCount++
        break
      }

      case 'blockquote': {
        blockquoteCount++
        node.children.map(o => walkNode(o))
        break
      }

      case 'image': {
        imageCount++
        break
      }

      case 'text': {
        textWordCount += countWords(node.value)
        textCharacterCount += node.value.length
        break
      }
    }
  }

  tree.children.forEach(o => walkNode(o))

  return {
    blockquoteCount,
    codeCount,
    dividerCount,
    emphasisCount,
    heading1Count,
    heading2Count,
    heading3Count,
    heading4Count,
    heading5Count,
    heading6Count,
    imageCount,
    inlineCodeCount,
    linkCount,
    listCount,
    listItemCount,
    paragraphCount,
    strikethroughCount,
    strongCount,
    tableCellCount,
    tableCount,
    tableRowCount,
    textCharacterCount,
    textWordCount
  } as const
}
