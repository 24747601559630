import Quill from '@/plugins/quill'

const Block = Quill.import('blots/block')

class Divider extends Block {
  static create (value: any) {
    const node = super.create(value) as HTMLInputElement
    node.addEventListener('keydown', event => {
      if (event.key !== 'Enter') {
        event.preventDefault()
        event.stopPropagation()
      }
    })
    return node
  }
}

Divider.blotName = 'divider'
Divider.className = 'ql-divider'
Divider.tagName = 'input'

export default Divider
