import Quill from '@/plugins/quill'

import openExternalLink from './open-external-link'

const Link = Quill.import('formats/link')

Link.sanitize = (url: string) => {
  if (/(#.+|.+:\/\/)/.test(url)) {
    return url
  } else {
    return `https://${url.toLowerCase()}`
  }
}

export default (quill: any) => {
  const clickHandler = (event: MouseEvent & { target: HTMLElement }) => {
    if (
      event.target.tagName === 'A' &&
      (quill.options.readOnly || !quill.isEnabled() || event.type === 'auxclick' || event.metaKey || event.target.classList.contains('ql-preview'))
    ) {
      const { href } = event.target as any
      openExternalLink(href)
      if (quill.options.readOnly || event.metaKey) {
        quill.theme.tooltip.hide()
        quill.blur()
      }
    }
  }
  quill.container.addEventListener('auxclick', clickHandler)
  quill.container.addEventListener('click', clickHandler)
}
