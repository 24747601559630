export type StatusUpdate<L = {}, S = {}> = {
  error: boolean
  errorMessage: string
} | {
  idle: boolean
} | {
  loading: boolean
  loadingInfo: Partial<L>
} | {
  success: boolean
  successInfo: Partial<S>
}

export default class Status<L = {}, S = {}> {
  setAt = new Date()

  error = false
  errorMessage = ''

  idle = true

  loading = false
  private _loadingInfo: Partial<L> = {}

  success = false
  private _successInfo: Partial<S> = {}

  constructor (props?: StatusUpdate<L, S>) {
    if (props) {
      this.set(props)
    }
  }

  get secondsAgo () {
    return (new Date().getTime() - this.setAt.getTime()) / 1000
  }

  get loadingInfo () {
    return this._loadingInfo || {}
  }

  get successInfo () {
    return this._successInfo || {}
  }

  set (set: StatusUpdate<L, S>) {
    if ('error' in set && set.error !== undefined) {
      this.error = set.error
      this.errorMessage = set.errorMessage || ''
    } else {
      this.error = false
      this.errorMessage = ''
    }
    if ('idle' in set && set.idle !== undefined) {
      this.idle = set.idle
    } else {
      this.idle = false
    }
    if ('loading' in set && set.loading !== undefined) {
      this.loading = set.loading
      this._loadingInfo = set.loadingInfo || {}
    } else {
      this.loading = false
      this._loadingInfo = {}
    }
    if ('success' in set && set.success !== undefined) {
      this.success = set.success
      this._successInfo = set.successInfo || {}
    } else {
      this.success = false
      this._successInfo = {}
    }

    this.setAt = new Date()
  }

  static idle () {
    return {
      idle: true
    }
  }

  static loading <L = undefined> (loadingInfo: Partial<L> = {}) {
    return {
      loading: true,
      loadingInfo
    }
  }

  static success <S = undefined> (successInfo: Partial<S> = {}) {
    return {
      success: true,
      successInfo
    }
  }

  static error (errorMessage: string) {
    return {
      error: true,
      errorMessage
    }
  }
}
