import * as fasBug from '@fortawesome/free-solid-svg-icons/faBug'
import * as fasChevronLeft from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import * as fasCommentDots from '@fortawesome/free-solid-svg-icons/faCommentDots'
import * as fasGift from '@fortawesome/free-solid-svg-icons/faGift'
import * as fasImage from '@fortawesome/free-solid-svg-icons/faImage'
import * as fasSync from '@fortawesome/free-solid-svg-icons/faSync'
import * as fasUser from '@fortawesome/free-solid-svg-icons/faUser'
import * as fasUsers from '@fortawesome/free-solid-svg-icons/faUsers'

export default [
  fasBug.definition,
  fasChevronLeft.definition,
  fasCommentDots.definition,
  fasGift.definition,
  fasImage.definition,
  fasSync.definition,
  fasUser.definition,
  fasUsers.definition
] as const
