// Based on the following solution
// https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
// Read my detailed notes at: https://stackoverflow.com/a/65326844

import Router, { RawLocation, Route } from 'vue-router'

// Personal utils. Can be replaced with `Function` and `(e: Error) => any;` respectively.
type AnyFunction<RETURN_T = any> = (...args: any[]) => RETURN_T;
type ErrorHandlerFunction<RETURN_T = any> = (e: Error) => RETURN_T;

let isAugumented = false
export default () => {
  if (isAugumented) { return }
  isAugumented = true

  const originalPush = Router.prototype.push // eslint-disable-line @typescript-eslint/unbound-method
  function augmentedPush(location: RawLocation): Promise<Route>;
  function augmentedPush(location: RawLocation, onResolve?: AnyFunction, onReject?: ErrorHandlerFunction): void;
  function augmentedPush (this: Router, location: RawLocation, onResolve?: AnyFunction, onReject?: ErrorHandlerFunction): void | Promise<Route> {
    const boundOriginalPush = originalPush.bind(this)
    if (onResolve || onReject) {
      return boundOriginalPush(location, onResolve, onReject)
    } else {
      return boundOriginalPush(location)
        .catch((err) => {
          if (Router.isNavigationFailure(err, Router.NavigationFailureType.redirected)) {
            // whatever, we are fine if it's aborted due to navigation redirect
            return Promise.resolve(err.from)
          }
          // rethrow error
          console.log({ err })
          return Promise.reject(err)
        })
    }
  }

  Router.prototype.push = augmentedPush
}
