/**
 * Cross-browser support for caretPositionFromPoint.
 * This returns a `CaretPosition` like object instead
 * of a CaretPosition, since we can't create it for
 * browsers that don't support this API.
 */
export function caretPositionFromPoint (x: number, y: number): {
  offsetNode: Node;
  offset: number;
  getClientRect(): DOMRect | null;
} | null {
  // @ts-ignore
  if (document.caretPositionFromPoint) {
    // @ts-ignore
    const position = document.caretPositionFromPoint(x, y)
    return position
      ? {
          getClientRect () {
            return position.getClientRect()
          },
          offset: position.offset,
          offsetNode: position.offsetNode
        }
      : null
  } else {
    const range = document.caretRangeFromPoint(x, y)
    return range
      ? {
          getClientRect () {
            return range.getClientRects()[0]
          },
          offset: range.startOffset,
          offsetNode: range.startContainer
        }
      : null
  }
}

function getShadowRoots (node: Node, roots: ShadowRoot[] = []) {
  let iter: Node | Element | ShadowRoot | null = node
  while (iter.parentNode || (iter as any).host) {
    if (iter instanceof ShadowRoot) {
      roots.push(iter)
      iter = iter.host!
      continue
    }
    iter = iter.parentNode!
  }
  return roots
}

/**
 * Cross-browser support for caret range from point.
 * This is deprecated, but will be supported with a backfill
 * from `caretPositionFromPoint`.
 *
 * @deprecated
 */
export function caretRangeFromPoint (x: number, y: number) {
  const caretPositionFromPoint = (document as any).caretPositionFromPoint
  if (caretPositionFromPoint) {
    const position = caretPositionFromPoint(x, y)
    if (position) {
      const range = document.createRange()
      let offsetNode = position.offsetNode
      const shadowRoots = getShadowRoots(offsetNode)
      if (shadowRoots.length) {
        offsetNode = shadowRoots[shadowRoots.length - 1].host!
      }
      range.setStart(offsetNode, position.offset)
      range.setEnd(offsetNode, position.offset)
      return range
    }
    return null
  } else {
    return document.caretRangeFromPoint(x, y)
  }
}
