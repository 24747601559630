import { Assets, Constructor, Context } from '@icepanel/app-canvas'

import * as env from './env'

type Object = Context.Diagram.Obj.Type | Context.Dependency.Obj.Type

const DEBUG = false

export const OBJECTS: Object[] = []
export const OBJECT_POOL: Object[] = []

export const addObject = (object: Object) => {
  const index = OBJECTS.indexOf(object)
  if (index <= -1) {
    OBJECTS.push(object)

    if (DEBUG) {
      console.log(`[ObjectPool] Add ${object.type} object ${object.id}`)
    }
  }
}

export const removeObject = (object: Object) => {
  const index = OBJECTS.indexOf(object)
  if (index > -1) {
    OBJECTS.splice(index, 1)

    if (DEBUG) {
      console.log(`[ObjectPool] Remove ${object.type} object ${object.id}`)
    }
  }
}

export const recycleObject = (object: Object) => {
  OBJECT_POOL.push(object)

  if (DEBUG) {
    console.log(`[ObjectPool] Recycle ${object.type} object ${object.id}`)
  }
}

export const reuseObject = <T extends Object> (type: Constructor<T>, newId?: string) => {
  const object = OBJECT_POOL.find((o): o is T => o instanceof type)
  if (object) {
    const index = OBJECT_POOL.indexOf(object)
    OBJECT_POOL.splice(index, 1)
    if (newId) {
      object.id = newId
    }
    if (DEBUG) {
      console.log(`[ObjectPool] Reuse ${object.type} object ${object.id}`)
    }
    return object
  } else {
    return null
  }
}

export const pruneObjects = () => {
  if (!OBJECT_POOL.length) {
    return
  }

  const randomIndex = Math.floor(Math.random() * OBJECT_POOL.length)
  const obj = OBJECT_POOL[randomIndex]
  if (obj) {
    const textureCacheIds = 'iconTexture' in obj && obj.iconTexture ? obj.iconTexture.textureCacheIds.filter(o => o.startsWith(env.ICON_URL)) : null

    // cleanup object
    OBJECT_POOL.splice(randomIndex, 1)
    removeObject(obj)
    obj.destroy()

    // clean up icon texture if no other object is using it
    if (textureCacheIds?.length) {
      const textureCacheIdsInUse = OBJECTS
        .map(o => 'iconTexture' in o && o.iconTexture ? o.iconTexture.textureCacheIds : [])
        .flat()
        .filter(o => o.startsWith(env.ICON_URL))

      textureCacheIds
        .filter(o => !textureCacheIdsInUse.includes(o))
        .forEach(o => {
          console.log(`[ObjectPool] Prune unused texture ${o}`)
          Assets.unload(o)
        })
    }

    if (DEBUG) {
      console.log(`[ObjectPool] Prune 1/${OBJECT_POOL.length + 1} objects`)
    }
  }
}
