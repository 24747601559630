// eslint-disable-next-line
import 'reflect-metadata'
// eslint-disable-next-line
import Vue from 'vue'
// eslint-disable-next-line
import 'vue-router'
// eslint-disable-next-line
import 'vuetify'

import 'quill/dist/quill.snow.css'
import 'swiper/swiper-bundle.css'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import './scss/quill-table-ui.scss'
import './scss/quill-mention.scss'
import './scss/quill.scss'
import './register-service-worker'
import './helpers/structured-clone-polyfill'
import '@/plugins/firebase'
import '@/plugins/api'
import '@/plugins/mixpanel'
import '@/plugins/sentry'

import { library } from '@fortawesome/fontawesome-svg-core'
import SwiperCore, { Navigation } from 'swiper/core'
import VueYoutube from 'vue-youtube'

import App from '@/app.vue'
import augmentRouterPush from '@/helpers/router-augmented-push'
import * as modules from '@/modules'
import * as filters from './helpers/filters'
import Quill from '@/plugins/quill'
import router from '@/plugins/router'
import store from '@/plugins/store'
import { startAnimationUpdate } from '@/plugins/tween'
import vuetify from '@/plugins/vuetify'

import QuillClickLink from './helpers/quill-click-link'
import QuillDivider from './helpers/quill-divider'
import QuillMention from './helpers/quill-mention'
import QuillTableUI from './helpers/quill-table-ui'

Vue.config.productionTip = false

Vue.use(VueYoutube)

SwiperCore.use([Navigation])

Quill.register({
  'formats/divider': QuillDivider,
  'modules/clickLink': QuillClickLink,
  'modules/mention': QuillMention,
  'modules/tableUI': QuillTableUI
})

try {
  // remove legacy data
  localStorage.removeItem('inviteTeamMemberReminder')
  localStorage.removeItem('teamTab')
  localStorage.removeItem('billingCurrency')
  localStorage.removeItem('diagramPan')
  localStorage.removeItem('diagramScale')
  localStorage.removeItem('githubRepoMenuScroll')
  localStorage.removeItem('azure-devopsRepoMenuOpen')
  localStorage.removeItem('trustMenuSelectedId')
  localStorage.removeItem('modelMenuSelectedIndex')
  localStorage.removeItem('registerPlan')
  localStorage.removeItem('githubRepoMenuOpen')
  localStorage.removeItem('azure-devopsRepoMenuScroll')
  localStorage.removeItem('trustMenuTab')
  localStorage.removeItem('trustMenuLinksSectionKey')
  localStorage.removeItem('inviteOrganizationMemberReminder')
  if (localStorage.getItem('loginDates')) {
    localStorage.setItem('whatsNewVersionsSeen', '["1.0"]')
  }
  localStorage.removeItem('loginDates')
  localStorage.removeItem('c4ModelHelpDialog')
  localStorage.removeItem('lastDomainIds')
  localStorage.removeItem('lastOrganizationId')
  localStorage.removeItem('lastLandscapeIds')
  localStorage.removeItem('lastTeamIds')
  localStorage.removeItem('trainingOverlayDismissed')
} catch (err) {
  console.error(err)
}

startAnimationUpdate()

augmentRouterPush()

Vue.filter('capitalize', filters.capitalize)
Vue.filter('date', filters.date)
Vue.filter('time', filters.time)
Vue.filter('dateTime', filters.dateTime)

modules.install({
  Vue,
  library,
  router,
  store,
  vuetify
})

const vue = new Vue({
  render: h => h(App),
  router,
  store,
  vuetify
})

vue.$mount('#app')

export default vue
