import * as analytics from '@icepanel/app-analytics'

import { landscapeId } from '@/modules/landscape/helpers/analytics-group'
import { organizationId } from '@/modules/organization/helpers/analytics-group'

import { pinType } from './analytics-properties'

/**
 * Screens
 */

export const pageNotFoundScreen = new analytics.Event({
  displayName: 'Page not found screen',
  name: 'page_not_found_screen',
  tags: [analytics.Tags.Screen]
})

/**
 * Dialogs
 */

export const whatsNewDialog = new analytics.Event({
  displayName: 'Whats new dialog',
  name: 'whats_new_dialog',
  tags: [analytics.Tags.Dialog]
})
export const licensesDialog = new analytics.Event({
  displayName: 'Licenses dialog',
  name: 'licenses_dialog',
  tags: [analytics.Tags.Dialog]
})

/**
 * Links
 */

export const feedbackLink = new analytics.Event({
  displayName: 'Feedback link',
  name: 'feedback_link',
  tags: [analytics.Tags.Link]
}, {
  landscapeId: {
    ...landscapeId,
    required: false
  },
  organizationId: {
    ...organizationId,
    required: false
  }
})
export const privacyPolicyLink = new analytics.Event({
  displayName: 'Privacy policy link',
  name: 'privacy_policy_link',
  tags: [analytics.Tags.Link]
}, {
  landscapeId: {
    ...landscapeId,
    required: false
  },
  organizationId: {
    ...organizationId,
    required: false
  }
})
export const termsOfServiceLink = new analytics.Event({
  displayName: 'Terms of service link',
  name: 'terms_of_service_link',
  tags: [analytics.Tags.Link]
}, {
  landscapeId: {
    ...landscapeId,
    required: false
  },
  organizationId: {
    ...organizationId,
    required: false
  }
})
export const isolationPriceCalculatorLink = new analytics.Event({
  displayName: 'Enterprise price calculator link',
  name: 'isolation_price_calculator_link',
  tags: [analytics.Tags.Link]
}, {
  landscapeId: {
    ...landscapeId,
    required: false
  },
  organizationId: {
    ...organizationId,
    required: false
  }
})

/**
 * Events
 */

export const pinItem = new analytics.Event({
  displayName: 'Pin item',
  name: 'pin_item'
}, {
  pinType
})
export const unpinItem = new analytics.Event({
  displayName: 'Unpin item',
  name: 'unpin_item'
}, {
  pinType
})

/**
 * Hidden
 */

export const enterpriseContactLinkHidden = new analytics.Event({
  displayName: 'Enterprise contact link',
  hidden: true,
  name: 'enterprise_contact_link',
  tags: [analytics.Tags.Link]
}, {
  landscapeId: {
    ...landscapeId,
    required: false
  },
  organizationId: {
    ...organizationId,
    required: false
  }
})
export const enterprisePriceCalculatorLinkHidden = new analytics.Event({
  displayName: 'Enterprise price calculator link',
  hidden: true,
  name: 'enterprise_price_calculator_link',
  tags: [analytics.Tags.Link]
}, {
  landscapeId: {
    ...landscapeId,
    required: false
  },
  organizationId: {
    ...organizationId,
    required: false
  }
})
export const dpaLinkHidden = new analytics.Event({
  displayName: 'DPA link',
  hidden: true,
  name: 'dpa_link',
  tags: [analytics.Tags.Link]
}, {
  landscapeId: {
    ...landscapeId,
    required: false
  },
  organizationId: {
    ...organizationId,
    required: false
  }
})
