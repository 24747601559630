import { CatalogTechnology, ModelObjectIcon, ModelObjectTechnology } from '@icepanel/platform-api-client'

import * as env from './env'

export const name: 'light' | 'dark' = new URLSearchParams(window.location.search).get('theme') === 'light' ? 'light' : 'dark'

export const iconUrlForTheme = (object: ModelObjectIcon | CatalogTechnology | ModelObjectTechnology, theme = name, iconUrl = env.ICON_URL) => {
  let urlDark: string | undefined
  let urlLight: string | undefined

  if ('url' in object || 'urlDark' in object || 'urlLight' in object) {
    urlDark = object.urlDark || object.url
    urlLight = object.urlLight || object.url
  } else if ('iconUrl' in object || 'iconUrlDark' in object || 'iconUrlLight' in object) {
    urlDark = object.iconUrlDark || object.iconUrl
    urlLight = object.iconUrlLight || object.iconUrl
  }

  if (theme === 'dark' && urlDark) {
    return `${iconUrl}${urlDark}`
  } else if (theme === 'light' && urlLight) {
    return `${iconUrl}${urlLight}`
  } else {
    return undefined
  }
}
