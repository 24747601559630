export default (element?: HTMLElement) => {
  if (!element) {
    return {
      left: 0,
      top: 0
    }
  }
  let top = 0
  let left = 0
  do {
    top += element.offsetTop || 0
    left += element.offsetLeft || 0
    element = element.offsetParent as HTMLElement
  } while (element)
  return {
    left,
    top
  }
}
